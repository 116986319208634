var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-link', {
    staticClass: "brand-logo d-flex",
    attrs: {
      "to": "/"
    }
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/logo/logo.png'),
      "alt": "logo"
    }
  }), _c('h2', {
    staticClass: "brand-text text-primary ml-1 mb-0 align-self-center"
  }, [_vm._v(" " + _vm._s(_vm.appTitle) + " ")])], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Login V2"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-card-title', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v(" Welcome to " + _vm._s(_vm.appTitle) + " ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Please login to your account ")]), _vm.isCredentialsShow ? _c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body font-small-2"
  }, [_c('p', [_c('small', {
    staticClass: "mr-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Email: ")]), _vm._v("demo@projectx.zone")])]), _c('p', [_c('small', {
    staticClass: "mr-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Password: ")]), _vm._v("BestOfficeManagementTool")])])]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.left",
      value: 'Use this login credentials only for the demo purpose',
      expression: "'Use this login credentials only for the demo purpose'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "position-absolute",
    staticStyle: {
      "top": "10",
      "right": "10"
    },
    attrs: {
      "icon": "HelpCircleIcon",
      "size": "18"
    }
  })], 1) : _vm._e(), _c('validation-observer', {
    ref: "loginValidation"
  }, [_c('b-form', {
    staticClass: "auth-login-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "login-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "required|email",
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "login-email",
            "state": errors.length > 0 ? false : null,
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('label', {
    attrs: {
      "for": "login-password"
    }
  }, [_vm._v("Password")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-forgot-password'
      }
    }
  }, [_c('small', [_vm._v("Forgot Password?")])])], 1), _c('validation-provider', {
    attrs: {
      "name": "Password",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "login-password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "login-password",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v(" Login ")])]], 2)], 1), _c('b-alert', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body font-small-2"
  }, [_c('p', [_c('small', {
    staticClass: "mr-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note:")]), _vm._v(" Empowering your business with an all-in-one solution that drives efficiency.")])])])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }